/* eslint-disable no-param-reassign */
import jwt_decode from "jwt-decode";
import { Page } from "@frontend/common/src/types/Page";
import { User, UserRole, UserType } from "@frontend/common/src/types/User";
import { LoginCredentials } from "@frontend/common/src/types/LoginCredentials";
import { createUrl } from "../../app/pages";

import { Context } from "..";

const dataOwner = process.env.REACT_APP_AUTH0_ADMINLOCATION;

export const login = async (
  { effects, actions }: Context,
  credentials: LoginCredentials
) => {
  try {
    const token = await effects.api.auth.login(
      credentials.username,
      credentials.password
    );
    if (token) {
      await actions.auth.onLoggedIn(token);
      if (effects.persistentCache.get("returnUrl")) {
        const returnUrl = effects.persistentCache.get("returnUrl");
        effects.persistentCache.remove("returnUrl");
        effects.router.navigateTo(returnUrl);
      } else {
        effects.router.navigateTo(createUrl(Page.ORDER_LIST));
      }
    } else {
      effects.toast("Innlogging feilet");
    }
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

// called on successfull login
export const onLoggedIn = async (
  { state, effects }: Context,
  token: string
) => {
  const decodedToken: {
    customerId: string;
    nameid: string;
    unique_name: string;
    role: UserRole;
    type: UserType;
  } = jwt_decode(token);

  const user: User = {
    id: decodedToken.nameid,
    customerId: decodedToken.customerId, // parseInt(decodedToken.customerId, 10),
    username: decodedToken.unique_name,
    role: decodedToken.role,
    type: decodedToken.type,
    isAdmin: decodedToken.role === UserRole.ADMIN,
  };
  state.auth.token = token;
  effects.tokenService.set(token);

  state.customer = await effects.api.customer.getById(user.customerId);
  state.pretreEmplyees = await effects.api.employees.getAll();
  state.customerEmployees =
    await effects.api.customerEmployee.getAllForCustomerId(user.customerId);

  const lookups = await effects.api.lookups.getLookups();
  state.buildingTypes = lookups.buildingTypes;
  state.technicalServices = lookups.technicalServices;
  state.offerOptions = lookups.offerOptions;
  state.mesterIdLogin = false;
  state.auth.user = user;
  if (user.type === UserType.EMPLOYEE) {
    state.currentEmployeeId = user.id;
  }
  if (effects.persistentCache.get("returnUrl")) {
    const returnUrl = effects.persistentCache.get("returnUrl");
    effects.persistentCache.remove("returnUrl");
    effects.router.navigateTo(returnUrl);
  } else {
    effects.router.navigateTo(createUrl(Page.ORDER_LIST));
  }
};

export const mockLoginWithToken = async (
  { actions }: Context,
  token: string
) => {
  await actions.auth.onLoggedIn(token);
};

export const logout = async ({ state, effects }: Context) => {
  try {
    effects.tokenService.clear();

    state.customer = null;
    state.currentEmployeeId = null;
    state.auth.user = null;

    // effects.router.navigateTo(createUrl(Page.LOGIN));
    // perform hard navigate to clear state.
    window.location.href = createUrl(Page.LOGIN);
  } catch (error: any) {
    alert(error.message);
  }
};

export const logoutMesterId = async ({ state, effects }: Context) => {
  try {
    effects.tokenService.clear();
    state.customer = null;
    state.currentEmployeeId = null;
    state.auth.user = null;
  } catch (error: any) {
    alert(error.message);
  }
};
interface mesterIdLogin {
  token: string;
  username: string;
}
export const onMesterIdLoggedIn = async (
  { state, effects, actions }: Context,
  { token, username }: mesterIdLogin
) => {
  try {
    const decodedToken = jwt_decode(token);
    actions.auth.updateToken(token);

    let customer;
    const customerId = effects.persistentCache.get("customerId");
    if (customerId !== undefined && customerId !== null) {
      customer = await effects.api.customer.getById(customerId);
    }
    const customers = await effects.api.customer.getByToken();
    if (customers.length < 1) {
      effects.router.navigateTo(createUrl(Page.HOME));
      throw new Error("Ingen kunde-tilgang. Ta kontakt med administrator");
    }
    const user = await effects.api.customerEmployee.getByUserName(username);
    state.mesterIdCustomers = customers;
    state.pretreEmplyees = await effects.api.employees.getAll();
    state.mesterIdLogin = true;
    const lookups = await effects.api.lookups.getLookups();
    state.buildingTypes = lookups.buildingTypes;
    state.offerOptions = lookups.offerOptions;
    state.technicalServices = lookups.technicalServices;
    state.mesterIdLogin = true;
    const { locations } =
      // @ts-ignore
      decodedToken["https://mestergruppen.no/authorization"];
    const stateUser: User = {
      id: user.id,
      customerId: customers[0].id,
      username: user.email,
      role: locations.includes(dataOwner) ? UserRole.ADMIN : UserRole.USER,
      type: UserType.EMPLOYEE,
      isAdmin: locations.includes(dataOwner),
    };
    state.auth.user = stateUser;
    if (stateUser.type === UserType.EMPLOYEE) {
      state.currentEmployeeId = user.id;
    }
    if (customer !== undefined && stateUser.isAdmin) {
      effects.persistentCache.remove("customerId");
      state.mesterIdCustomers = [...state.mesterIdCustomers, customer];
      state.customer = customer;
      state.customerEmployees =
        await effects.api.customerEmployee.getAllForCustomerId(customer.id);
      effects.router.navigateTo(createUrl(Page.HOME));
    } else if (customers.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      state.customer = customers[0];
      state.customerEmployees =
        await effects.api.customerEmployee.getAllForCustomerId(customers[0].id);
      if (effects.persistentCache.get("returnUrl")) {
        const returnUrl = effects.persistentCache.get("returnUrl");
        effects.persistentCache.remove("returnUrl");
        effects.router.navigateTo(returnUrl);
      } else {
        effects.router.navigateTo(createUrl(Page.HOME));
      }
    } else if (effects.persistentCache.get("returnUrl")) {
      const returnUrl = effects.persistentCache.get("returnUrl");
      effects.persistentCache.remove("returnUrl");
      effects.router.navigateTo(returnUrl);
    } else {
      effects.router.navigateTo(createUrl(Page.HOME));
    }
  } catch (error: any) {
    effects.tokenService.clear();
    effects.router.navigateTo(createUrl(Page.HOME));
    effects.toast(error, 5000);
  }
};

export const onMesterIdSelectCustomer = async (
  { state, effects }: Context,
  customerId: string
) => {
  const customer = state.mesterIdCustomers.find((x) => x.id === customerId);
  state.customer = { ...customer };
  state.customerEmployees =
    await effects.api.customerEmployee.getAllForCustomerId(customer.id);

  if (effects.persistentCache.get("returnUrl")) {
    const returnUrl = effects.persistentCache.get("returnUrl");
    effects.persistentCache.remove("returnUrl");
    effects.router.navigateTo(returnUrl);
  } else {
    effects.router.navigateTo(createUrl(Page.HOME));
  }
};
export const forgotPassword = async (
  { effects, actions }: Context,
  credentials: { email: string }
) => {
  try {
    await effects.api.auth.forgotPassword(credentials.email);
    effects.toast("Nytt passord er sendt");
    actions.navigation.openLogin();
  } catch (error: any) {
    effects.toast(error, 5000);
  }
};

export const updateToken = ({ state, effects }: Context, newToken: string) => {
  state.auth.token = null;
  effects.tokenService.clear();
  state.auth.token = newToken;
  effects.tokenService.set(newToken);
};
