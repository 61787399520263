import axios from "axios";
import jwtDecode from "jwt-decode";
import { GetTokenSilentlyOptions } from "@auth0/auth0-react";

export const axiosTokenInterceptor = (
  state: any,
  actions: any,
  triggerLogout: () => void,
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>
) => {
  return axios.interceptors.request.use(
    async (config) => {
      const { token } = state.auth;

      if (!token) {
        triggerLogout();
        return Promise.reject(new Error("Ingen token, logger ut."));
      }

      const decoded: { exp: number } = jwtDecode(token);
      const tokenExpiry = decoded.exp * 1000;
      const currentTime = new Date().getTime();
      if (currentTime > tokenExpiry) {
        triggerLogout();
        return Promise.reject(new Error("Token utløpt, logger ut."));
      }

      if (tokenExpiry - currentTime < 15 * 60 * 1000) {
        const newToken = await getAccessTokenSilently({ ignoreCache: true });
        actions.auth.updateToken(newToken);

        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${newToken}`,
          },
        };
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
