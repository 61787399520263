/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FunctionComponent, useEffect, useRef, useState } from "react";

import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TextArea } from "@frontend/common/src/components/TextArea/TextArea";
import * as validators from "@frontend/common/src/validators";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios, { CancelTokenSource } from "axios";
import { Select } from "../../../components/Select/Select";

import { useActions, useAppState, useEffects } from "../../../overmind";
import { OfferOptionType } from "../../../types/OfferOption";

import { yesNoOptions } from "../../../data/selectOptions";
import { BlockButton } from "../../../components/Buttons/Buttons.styles";
import { DraftOrderSubProject } from "../../../types/DraftOrder/DraftOrderSubProject";
import { makeId } from "../../../helpers/GenerateId";
import { CMSContent } from "../../../components/CMSContent/CMSContent";
import { CalculatePriceModal } from "../../../modals/CalculatePriceModal/CalculatePriceModal";
import {
  Address,
  Calculation,
  CalculationPayload,
} from "../../../types/DraftOrder/Calculation";
import roof11 from "../../../assets/img/A-takstol med rafthøgde.png";
import roof1 from "../../../assets/img/A-takstol standard.png";
import roof10 from "../../../assets/img/A-takstol undergurt ut.png";
import def from "../../../assets/img/Blank.png";
import roof4 from "../../../assets/img/Pulttakstol standard.png";
import roof13 from "../../../assets/img/Pulttakstol undergurt ut.png";
import roof6 from "../../../assets/img/Saksetakstol.png";
import roof2 from "../../../assets/img/W-takstol standard.png";
import roof12 from "../../../assets/img/W-takstol undergurt ut.png";
import Tooltip from "./Tooltip";
import { CalcConditions } from "./CalcConditions";
import {
  approvedRoofTypes,
  configParams,
  ww,
} from "../../../helpers/PriceCalculatorConfig";
import { CalculationPriceAndError } from "./CalculationPriceAndError";

export type FormProps = {
  subProjects: DraftOrderSubProject[];
  setSubProjects: (projects: DraftOrderSubProject[]) => void;
  setActiveForm: (form: string) => void;
  selectedSubProject: DraftOrderSubProject | undefined;
  setSelectedSubProject: (project: DraftOrderSubProject | undefined) => void;
  autoFill: boolean;
  quickChoice?: string;
  onMainCancel: () => void;
};

export const ExtendedRoofForm: FunctionComponent<FormProps> = ({
  setSubProjects,
  setActiveForm,
  subProjects,
  selectedSubProject,
  setSelectedSubProject,
  autoFill,
  quickChoice,
  onMainCancel,
}) => {
  const { t } = useTranslation();
  const [calc, setCalc] = useState<Calculation | undefined>(undefined);
  const { offerOptions, orders, modals, customer } = useAppState();
  const actions = useActions();
  const effects = useEffects();
  const [img, setImg] = useState(def);
  const [priceDisabled, setPriceDisabled] = useState<boolean>(true);
  const [showPriceButton, setShowPriceButton] = useState<boolean>(false);
  const [configParam, setConfigParam] = useState(undefined);
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const cancelToken: React.MutableRefObject<CancelTokenSource | null> =
    useRef(null);

  const onSubmit = (data: DraftOrderSubProject) => {
    if (data.projectId === undefined || data.projectId.length < 1) {
      // eslint-disable-next-line no-param-reassign
      data.projectId = makeId(8);
    }
    if (calc !== undefined) {
      // eslint-disable-next-line no-param-reassign
      data.conditions = calc.conditions;
      // eslint-disable-next-line no-param-reassign
      data.preconditions = calc.preconditions;
      // eslint-disable-next-line no-param-reassign
      data.priceConditions = calc.priceConditions;
    }
    if (selectedSubProject !== undefined) {
      const newProjs = subProjects.map((x) => {
        if (x.projectId === data.projectId) {
          return {
            ...x,
            comment: data.comment,
            rangeDistance:
              data.rangeDistance ?? selectedSubProject.rangeDistance,
            roofType: data.roofType ?? selectedSubProject.roofType,
            roofingType: data.roofingType ?? selectedSubProject.roofingType,
            angle: data.angle ?? selectedSubProject.angle,
            protrusion: data.protrusion ?? selectedSubProject.protrusion,
            workingWidth: data.workingWidth ?? selectedSubProject.workingWidth,
            additionalCrane:
              data.additionalCrane ?? selectedSubProject.additionalCrane,
            roomHeight: data.roomHeight ?? selectedSubProject.roomHeight,
            roomWidth: data.roomWidth ?? selectedSubProject.roomWidth,
            numberOfTrusses:
              data.numberOfTrusses ?? selectedSubProject.numberOfTrusses,
            price: data.price ?? selectedSubProject.price,
            calculationId:
              data.calculationId ?? selectedSubProject.calculationId,
            priceConditions:
              data.priceConditions ?? selectedSubProject.priceConditions,
            conditions: data.conditions ?? selectedSubProject.conditions,
            preconditions:
              data.preconditions ?? selectedSubProject.preconditions,
          };
        }
        return x;
      });
      setSubProjects(newProjs);
    } else {
      setSubProjects([...subProjects, data]);
    }
    setActiveForm("");
    setSelectedSubProject(undefined);
  };
  const onCancel = () => {
    setActiveForm("");
    setSelectedSubProject(undefined);
    if (quickChoice !== undefined) {
      onMainCancel();
    }
  };

  const cancelCalculation = () => {
    if (cancelToken.current != null) {
      cancelToken.current.cancel();
    }
    actions.modals.calculatePriceModal.close();
  };

  const roof = yup.object().shape({
    roofType: validators.requiredField,
    roofingType: validators.requiredField,
    angle: validators.decimalField,
    rangeDistance: validators.positiveNumeric,
    protrusion: validators.positiveNumeric.min(0, "Må være over 0"),
    additionalCrane: !orders.currentNewOrder.quotation
      ? validators.requiredField
      : null,
    workingWidth: validators.positiveNumeric,
    numberOfTrusses: validators.positiveNumeric,
    roomWidth: validators.positiveNumeric,
    roomHeight: validators.positiveNumeric,
  });
  const { register, handleSubmit, errors, setValue, watch, getValues } =
    useForm<DraftOrderSubProject>({
      // @ts-ignore
      resolver: yupResolver(roof),
      defaultValues: selectedSubProject,
    });

  const roofType = watch("roofType");

  const watcher = watch([
    "numberOfTrusses",
    "protrusion",
    "rangeDistance",
    "angle",
    "roofingType",
  ]);

  useEffect(() => {
    if (selectedSubProject) {
      if (selectedSubProject.price) {
        setCalc({
          price: selectedSubProject.price,
          uid: selectedSubProject.calculationId,
          conditions: selectedSubProject.conditions ?? [],
          preconditions: selectedSubProject.preconditions ?? [],
          specifications: [],
          freight: selectedSubProject.freight,
          priceConditions: selectedSubProject.priceConditions ?? [],
        });
      }
    }
  }, [selectedSubProject]);
  useEffect(() => {
    switch (roofType) {
      case "1":
        setImg(roof1);
        break;
      case "2":
        setImg(roof2);
        break;
      case "4":
        setImg(roof4);
        break;
      case "6":
        setImg(roof6);
        break;
      case "10":
        setImg(roof10);
        break;
      case "11":
        setImg(roof11);
        break;
      case "12":
        setImg(roof12);
        break;
      case "13":
        setImg(roof13);
        break;
      default:
        setImg(def);
    }
  }, [roofType]);
  const roofTypes = offerOptions
    .filter((o) => o.type === OfferOptionType.ROOF)
    .map((o) => ({
      label:
        o.description +
        (approvedRoofTypes.includes(o.id.toString()) ? " (K)" : ""),
      value: o.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const roofingTypes = offerOptions
    .filter((o) => o.type === OfferOptionType.ROOFING)
    .map((o) => ({ label: o.description, value: o.id }));

  const calclulatePrice = () => {
    actions.modals.calculatePriceModal.open();
    cancelToken.current = axios.CancelToken.source();
    const formValues = getValues([
      "projectId",
      "numberOfTrusses",
      "angle",
      "workingWidth",
      "roofType",
      "rangeDistance",
      "roofingType",
      "protrusion",
      "roomHeight",
      "roomWidth",
    ]);
    const uid =
      formValues.projectId !== null && formValues.projectId.length > 0
        ? formValues.projectId
        : makeId(4);
    const { currentNewOrder } = orders;
    const address: Address = {
      street: currentNewOrder.deliveryAddress,
      height: Number(currentNewOrder.mamsl),
      city: currentNewOrder.city,
      zipCode: currentNewOrder.zipCode,
      gardsnr:
        currentNewOrder.farmNumber !== null
          ? currentNewOrder.farmNumber.toString()
          : null,
      bruksnr:
        currentNewOrder.subFarmNumber !== null
          ? currentNewOrder.subFarmNumber.toString()
          : null,
    };
    const body: CalculationPayload = {
      uid,
      projectType: "Takstol",
      buildingType:
        currentNewOrder.buildingType.length > 0
          ? currentNewOrder.buildingType
          : null,
      CustomerId: customer.id,
      deliveryterms: currentNewOrder.deliveryTerms,
      trussInfo: {
        trussType: formValues.roofType,
        Quantity: Number(formValues.numberOfTrusses),
        SpanWidth: Number(formValues.rangeDistance),
        RoofAngle: Number(formValues.angle),
        TimberWidth: Number(formValues.workingWidth),
        RoofSheeting: formValues.roofingType,
        roofOverhang: Number(formValues.protrusion),
        // roomHeight: Number(formValues.roomHeight),
        // roomWidth: Number(formValues.roomWidth),
      },
      address,
    };
    effects.api.order
      .calculateRoof(body, cancelToken.current)
      .then((data) => {
        setCalc(data);
        setValue("price", data.price);
        setValue("calculationId", data.uid);
        setValue("freight", data.freight);
        cancelToken.current = null;
        actions.modals.calculatePriceModal.close();
      })
      .catch(() => {
        effects.toast("Noe gikk galt, vennligst prøv igjen senere", 5000);
        cancelToken.current = null;
        actions.modals.calculatePriceModal.close();
      });
  };
  const validatePriceButton = (roofParams: {
    numberOfTrusses?: number;
    rangeDistance?: number;
    protrusion?: number;
    angle?: number;
    roofingType?: string;
  }): boolean => {
    if (configParam === undefined) {
      return true;
    }
    if (
      roofParams.roofingType === undefined ||
      roofParams.roofingType === null ||
      roofParams.roofingType.length === 0
    ) {
      return true;
    }
    const trusses = Number(roofParams.numberOfTrusses);
    if (trusses >= 1 && trusses <= 99) {
      const range = Number(roofParams.rangeDistance);
      if (
        range >= configParam.minRangeDistance &&
        range <= configParam.maxRangeDistance
      ) {
        const protrusion = parseInt(roofParams.protrusion.toString(), 10);

        if (
          protrusion >= configParam.minProtrusion &&
          protrusion <= configParam.maxProtrusion
        ) {
          const angle = Number(roofParams.angle);
          if (angle >= configParam.minAngle && angle <= configParam.maxAngle) {
            return false;
          }
        }
      }
    }
    return true;
  };

  useEffect(() => {
    const approvedRoof = approvedRoofTypes.includes(roofType);
    if (approvedRoof) {
      const param = configParams.find((x) => x.roofType === roofType);
      setConfigParam(param);
    }
    setShowPriceButton(approvedRoof);
    const disabled = validatePriceButton(watcher) && approvedRoof;
    setPriceDisabled(disabled);
  }, [roofType, watcher]);

  const toolTipContent = () => {
    if (configParam === undefined) return null;

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>
          Nødvendige felter for{" "}
          {roofTypes.find((x) => x.value.toString() === roofType).label}{" "}
        </span>
        <span>Antall takstoler: 1-99</span>
        <span>
          Takvinkel: {configParam.minAngle} - {configParam.maxAngle}
        </span>
        <span>
          Spennvidde: {configParam.minRangeDistance} -{" "}
          {configParam.maxRangeDistance}
        </span>
        <span>
          Utstikk: {configParam.minProtrusion} - {configParam.maxProtrusion}{" "}
        </span>
      </div>
    );
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
          fontSize: "1rem",
          flexWrap: "wrap",
        }}
      >
        <div>
          <input
            ref={register}
            type="hidden"
            value="extendedRoof"
            name="type"
          />
          <input ref={register} type="hidden" name="projectId" />
          <input ref={register} type="hidden" name="price" />
          <input ref={register} type="hidden" name="calculationId" />
          <input ref={register} type="hidden" name="freight" />
          <Select
            name="roofType"
            label={t("Pick roof type")}
            options={roofTypes}
            required
            inputRef={register}
            readonly={calc !== undefined}
            error={errors.roofType?.message}
            disabled={
              autoFill &&
              selectedSubProject.roofType !== null &&
              selectedSubProject.roofType.length > 0
            }
          />
          <div style={{ fontSize: "0.75rem" }}>
            <CMSContent contentId={1470} />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            <Select
              name="roofingType"
              label={t("Roofing")}
              options={roofingTypes}
              inputRef={register}
              readonly={calc !== undefined}
              error={errors.roofingType?.message}
              disabled={
                autoFill &&
                selectedSubProject.roofingType !== null &&
                selectedSubProject.roofType.length > 0
              }
              required
            />
            <FormInput
              readonly={calc !== undefined}
              label={t("Antall takstoler")}
              type="number"
              name="numberOfTrusses"
              suffix="stk"
              inputRef={register}
              disabled={autoFill}
              error={errors.angle?.message}
            />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            <FormInput
              label={t("Roof angle")}
              type="number"
              name="angle"
              suffix="grader"
              inputRef={register}
              readonly={calc !== undefined}
              disabled={autoFill}
              error={errors.angle?.message}
            />
            <FormInput
              readonly={calc !== undefined}
              label={t("Range distance")}
              type="number"
              suffix="mm"
              name="rangeDistance"
              inputRef={register}
              disabled={autoFill}
              error={errors.rangeDistance?.message}
            />
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            <FormInput
              label={t("Protrusion")}
              type="number"
              name="protrusion"
              suffix="mm"
              inputRef={register}
              readonly={calc !== undefined}
              disabled={autoFill}
              error={errors.protrusion?.message}
            />

            <Select
              label={t("Virkesbredde")}
              options={ww}
              name="workingWidth"
              inputRef={register}
              readonly={calc !== undefined}
              disabled={autoFill}
              error={errors.workingWidth?.message}
              noDefault
            />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            {/*
              <FormInput
              label={t("Romhøyde")}
              type="number"
              name="roomHeight"
              suffix="mm"
              inputRef={register}
              readonly={calc !== undefined}
              disabled={autoFill}
              error={errors.protrusion?.message}
            />

            <FormInput
              label={t("Rombredde")}
              type="number"
              name="roomWidth"
              suffix="mm"
              inputRef={register}
              readonly={calc !== undefined}
              disabled={autoFill}
              error={errors.workingWidth?.message}
            />
               */}
          </div>
          {!orders.currentNewOrder.quotation && (
            <Select
              name="additionalCrane"
              label={t("Ekstra kraning ")}
              options={yesNoOptions}
              inputRef={register}
              error={errors.additionalCrane?.message}
              disabled={
                autoFill &&
                selectedSubProject.additionalCrane !== null &&
                selectedSubProject.additionalCrane.length > 0
              }
              required={!orders.currentNewOrder.quotation}
            />
          )}

          <TextArea
            label={t("Kommentar")}
            name="comment"
            rows={3}
            inputRef={register}
            placeholder={t("Write other required info here")}
          />
          {calc === undefined && showPriceButton ? (
            <div
              style={{
                display: "inline",
                position: "relative",
              }}
              onMouseEnter={() => setShowToolTip(true)}
              onMouseLeave={() => setShowToolTip(false)}
            >
              <Tooltip show={showToolTip}>{toolTipContent()}</Tooltip>
              <BlockButton
                style={{
                  marginRight: "2em",
                  backgroundColor: priceDisabled ? "gray" : "green",
                  width: "12em",
                }}
                onClick={() => calclulatePrice()}
                type="button"
                disabled={priceDisabled}
              >
                Beregn pris
              </BlockButton>
            </div>
          ) : calc ? (
            <BlockButton
              style={{
                marginRight: "2em",
                backgroundColor: "green",
                width: "12em",
              }}
              onClick={() => setCalc(undefined)}
              type="button"
            >
              Rediger
            </BlockButton>
          ) : null}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "576px" }}>
            <img
              style={{ width: "576px", height: "288px" }}
              alt="takstol"
              src={img}
            />
            <CalculationPriceAndError calc={calc} />
          </div>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "flex-end",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: "auto",
            }}
          >
            <BlockButton
              style={{ marginRight: "2em", width: "12em", fontSize: "1rem" }}
              type="submit"
            >
              {selectedSubProject !== undefined ? "Oppdater" : "Legg til"}
            </BlockButton>
            <BlockButton
              style={{ width: "12em", fontSize: "1rem" }}
              onClick={() => onCancel()}
              type="button"
            >
              Avbryt
            </BlockButton>
          </div>
          <CalcConditions calc={calc} />
        </div>
      </div>
      {modals.calculatePriceModal.isCurrent ? (
        <CalculatePriceModal cancelCalculation={cancelCalculation} />
      ) : null}
    </form>
  );
};
